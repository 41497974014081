"use client"

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html lang="pl">
      <body>
        <h2>Coś poszło nie tak!</h2>
        <p>{error.message}</p>
        {(error.digest || error.stack) && (
          <details>
            <summary>Więcej szczegółów</summary>
            {error.digest && <p>{error.digest}</p>}
            {error.stack && <pre>{error.stack}</pre>}
          </details>
        )}
        <button onClick={() => reset()}>Spróbuj ponownie</button>
      </body>
    </html>
  )
}
